import React, { Component } from "react";
import queryString from 'query-string'

import Loader from './Pages/Components/Loader';


export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null
      };
    }

    async componentDidMount() {
      const params = queryString.parse(this.props.location.search);

      if (!(params.category) && process.env.REACT_APP_REDIRECT_TO_NEW_ONLINE_BOOKING === "true" && process.env.REACT_APP_REDIRECT_TO_NEW_ONLINE_BOOKING_MOVING_ONLY !== "true") {
        return document.location = process.env.REACT_APP_NEW_ONLINE_BOOKING_URL;
      }

      if (params.src === "v3") {
        sessionStorage.setItem("source-app", params.src);
      }

      const { default: component } = await importComponent();

      this.setState({
        component
      });
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : <Loader/>;
    }
  }

  return AsyncComponent;
}